import { Box, Text } from '@chakra-ui/layout';
import React from 'react';
import { footerHeightInPx } from './Footer';
import { headerHeightInPx } from './HeaderBar';

export default ({ text }) => {
  return (
    <Box
      minH={`calc(100vh - ${headerHeightInPx + footerHeightInPx}px)`}
      px={['10px', '100px']}
      py={['20px', '40px']}
    >
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </Box>
  );
};
