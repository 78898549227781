import { graphql } from 'gatsby';
import React from 'react';
import FatText from '../components/FatText';
import Footer from '../components/Footer';

import HeaderBar from '../components/HeaderBar';

const impText = `
<h1 style="font-size:26px">Impressum</h1>

<h2 style="font-size:22px">Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
<p>brainCheck GmbH<br />
Römerstraße 13<br />
50226 Frechen</p>

<p>Handelsregister: HRB 70161<br />
Registergericht: Amtsgericht K&ouml;ln</p>

<p><strong>Vertreten durch:</strong><br />
Annette Reher</p>

<h2 style="font-size:22px">Kontakt</h2>
<p>Telefon: +491722911348<br />
E-Mail: happy@braincheck.de</p>

<h2 style="font-size:22px">Umsatzsteuer-ID</h2>
<p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
DE815212852</p>

<h2 style="font-size:22px">Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

<h3>Haftung f&uuml;r Inhalte</h3> <p>Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen.</p> <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p> <h3>Haftung f&uuml;r Links</h3> <p>Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. 
`;

export default () => {
  return (
    <>
      <HeaderBar />
      <FatText text={impText} />
      <Footer />
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
